import { Formik, FormikErrors } from 'formik';
import { useCallback } from 'react';

import { BasicPage, TopSummary } from '@components';
import { ErrorMessages } from '@entities/enums';
import { WindowLocation } from '@reach/router';
import { Repair, repairGroup } from '@services/RepairService';
import { RepairDetailsForm } from '@src/modules/RepairDetails/RepairDetailsForm';
import { Quote } from '@entities/types';
import { getStepTitle } from '@utils/getStepTitle';

interface Props {
  location: WindowLocation;
  quote?: Quote;
  initialValues: Repair;
  isLoggedIn: boolean;

  onClickBack(): void;

  onSubmit(values: Repair): void;
}

export const RepairDetails = (props: Props) => {
  const title = 'Repair details';
  const aboveHeading = getStepTitle(props.location);

  const validate = useCallback((values: Repair): FormikErrors<Repair> => {
    const errors: FormikErrors<Repair> = {};

    if (!values.repairIssue || !values.problemType) {
      if (!values.repairIssue) {
        errors.repairIssue = ErrorMessages.REQUIRED;
      }

      if (!values.problemType) {
        errors.problemType = ErrorMessages.REQUIRED;
      }

      return errors;
    }

    const problemTypes = repairGroup.get(values.repairIssue) ?? [];
    const repairProblem = problemTypes.find(
      (_) => _.problemType === values.problemType
    );

    if (repairProblem?.needAdditionalInfo && !values.notes) {
      errors.notes = ErrorMessages.REQUIRED;
    }

    if (repairProblem?.blockedSale) {
      errors.problemType = ' ';
    }

    if (repairProblem?.blockedSaleIfNo && values.confirm !== 'yes') {
      errors.confirm = ' ';
    }

    if (values.notes && values.notes.length > 225) {
      errors.notes = ErrorMessages.TEXT_MAX_LENGTH;
    }

    return errors;
  }, []);

  if (!props.quote) return null;

  return (
    <BasicPage
      pageName="repair-details"
      quote={props.quote}
      location={props.location}
      shouldIncludeFootnotes={true}
      warnOnLeaving
    >
      <TopSummary {...{ title, aboveHeading }} />
      <Formik
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <RepairDetailsForm
          quote={props.quote}
          onClickBack={props.onClickBack}
        />
      </Formik>
    </BasicPage>
  );
};
